<template>
  <section>
    <b-row>
      <b-col cols="5" align="left" class="mt-3">
        <activity-photo-form :activity="activity" @change="updateActivityPhoto" />
      </b-col>
      <b-col align="left">
        <b-row class="mt-1" no-gutters>
          <b-col cols="12">
            <label class="col-form-label-sm field-name-class mb-0">
              {{ $t(`admin.activity.form.name`) }}
            </label>
          </b-col>
          <b-col cols="12">
            <d-text-field
              v-model.trim="activity.name"
              :rules="validation.text"
              size="sm"
              place-holder="admin.activity.form.place-holder-name"
              error-text="validation.text-input-three-characters"
            />
          </b-col>
        </b-row>
        <b-row class="mt-3" no-gutters>
          <score-type-selector v-model="activity.scoreType" />
        </b-row>
        <b-row
          v-if="activity.id"
          class="iframe-container mt-3"
          no-gutters
          @click="copy()"
        >
          <label class="col-form-label-sm field-name-class mb-0">
            <span>
              {{ $t(`match.score.id`) }}
            </span>
          </label>
          <i class="fa fa-copy copyIcon" />
          <code class="iframe">
            {{ activity.id }}
          </code>
        </b-row>
      </b-col>
    </b-row>

    <b-row class="mt-3" no-gutters>
      <levels-tab
        :activity="activity"
        @on:level:created-or-updated="$emit('on:level:created-or-updated')"
        @on:delete-level="$emit('on:delete-level')"
      />
    </b-row>
  </section>
</template>

<script>
import ActivityPhotoForm from "@/components/activities/ActivityPhotoForm.vue";
import ScoreTypeSelector from "@/components/activities/ScoreTypeSelector.vue";
import LevelsTab from "@custom/activities/level/LevelsTab.vue";

export default {
  components: {
    ActivityPhotoForm,
    ScoreTypeSelector,
    LevelsTab,
  },
  props: {
    activity: {
      type: Object,
      default: null,
    },
    validation: {
      type: Object,
      default: null,
    },
  },
  methods: {
    updateActivityPhoto (file) {
      this.activity.photo = file;
      if (this.activity.id) {
        this.$emit("on:activity-photo:updated", {
          activity: this.activity,
          photo: file,
        });
      }
    },
    copy () {
      navigator.clipboard.writeText(this.activity.id);
      this.$vs.notify({
        time: 10000,
        title: this.$t("general.actions.success-copy.title"),
        text: this.$t("general.actions.success-copy.content", { label: this.$t("match.score.id") }),
        color: "success",
        position: "top-right",
      });
    },
  },
};
</script>